export let ProjectCardData = {
    PageNumbering: {
        projectName: 'Page Numbering',
        description: 'Generate page numbers for slide decks in figma',
        pills: ["Figma Plugin", "Figma API"],
        coverImage: require('./Assets/ProjectCoverImages/PageNumbering.png')
    },
    PennResources :{
        projectName: 'Penn Resources',
        description: 'Crowd source supplemental resources for courses at UPenn',
        pills: ["React", "Firebase", "Responsive Design"],
        coverImage: require('./Assets/ProjectCoverImages/PennResources.png')
    },

    RecipeBook :{
        projectName: 'Recipe Book',
        description: 'Web Application that allows home cooks to record their favorite recipe',
        pills: ["React", "Front-End"],
        coverImage: require('./Assets/ProjectCoverImages/RecipeBook.png')
    },

    Jpm :{
        projectName: 'JP Morgan',
        description: 'Uncovering barriers that users face when using their mobile app.',
        pills: ['UX Research' , 'UX Design', 'Visual Design'],
        coverImage: require('./Assets/ProjectCoverImages/JPMorgan-Chase.png')
    },

    Kiavi: {
        projectName: 'Kiavi',
        description: 'Designing new offerings for real estate investors',
        pills: ['UX Research'],
        coverImage: require('./Assets/ProjectCoverImages/Kiavi.png')
    },

    // Blockour : {
    //     projectName : 'Blockour',
    //     description: "Project Coming Soon. I am working on a meditative game",
    //     pills: ['Unity' , 'Game Development'],
    //     coverImage: require('./Assets/Rectangle 29.jpeg')
    // },


    MedBuddy : {
        projectName : 'Med Buddy',
        description: 'MedBuddy uses IoT to give agency and power to elderly users over both their physical and mental health through community engagement.' ,
        pills:['2021', 'IoT', 'Service Design'],
        coverImage: require('./Assets/ProjectCoverImages/MedBuddy.png')
    },

    WindStax : {
        projectName : 'Windstax',
        description: 'How might we create a mobile app for Windstax users to help them manage their wind turbines.' ,
        pills:['2021', 'Mobile', 'UX'],
        coverImage: require('./Assets/ProjectCoverImages/Windstax.png')
    },

    GroupShopping : {
        projectName: 'Group Shopping',
        description: 'How might we create a convenient and serendipitous grocery shopping during pandemic.' ,
        pills:['2020', 'Responsive Design', 'UX' ,],
        coverImage: require('./Assets/ProjectCoverImages/GroupShopping.png')
    },

    PublicArt : {
        projectName: 'Public Art Engagement',
        description: 'Creating a platform for users to engage in conversations with each other and with artists' ,
        pills:['2020', 'UX Research', 'UX Design' ,],
        coverImage: require('./Assets/ProjectCoverImages/PublicArt.png')
    },

    firstPortfolio : {
        projectName: 'My First Portfolio',
        description: 'How might we create a convenient and serendipitous grocery shopping during pandemic.' ,
        pills:['2020', 'HTML/CSS', 'UI' ,],
        coverImage: require('./Assets/ProjectCoverImages/FirstPortfolio.png')
    },

    secondPortfolio : {
        projectName: 'My Second Portfolio',
        description: 'How might we create a convenient and serendipitous grocery shopping during pandemic.' ,
        pills:['2020' , 'HTML/CSS','Javascript', 'Bootstrap', 'UI'],
        coverImage: require('./Assets/ProjectCoverImages/SecondPortfolio.png')
    },

    BunBun : {
        projectName: 'Bun Bun Shop',
        description: 'How might we create a convenient and serendipitous grocery shopping during pandemic.' ,
        pills:['2020', 'HTML/CSS', 'UX'],
        coverImage: require('./Assets/ProjectCoverImages/BunBun.png')
    }

}